.dashboard-admin-label {
    color: #ff0000;
}

.nav-item i {
    margin-right: 1em;
}

.dash-dark {
    background-color: #250e4a !important;
}