@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/pulse/bootswatch";

.nav-link {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.nodrag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

@media print {
    a {
        text-decoration: none;
    }
    .no-print {
        display: none;
    }
    .no-print-break {
        break-inside: avoid;
    }
    .print-center {
        margin: 0 auto;
    }
}

input {
    background-color: rgb(245, 230, 255) !important;
}
.dash-table-order {
    cursor: pointer;
}

.dash-table-order-selected {
    color: blue !important;
}
