.conduct-list {
    border: 1px solid #ccc;
    width: 100%;
    table-layout: fixed;
}

.conduct-list-comments {
    overflow-wrap: break-word;
    background-color: red;
}

.conduct-list-item-date {
    border-right:  1px solid #ccc;
}
.conduct-list-item-date0 {
    background-color: rgb(227, 227, 227) !important; /* Sunday */
    
}
.conduct-list-item-date1 {
    background-color: rgb(249, 238, 255) !important; /* Monday */
}
.conduct-list-item-date2 {
    background-color: rgb(255, 249, 234) !important;   /* Tuesday */
}
.conduct-list-item-date3 {
    background-color: rgb(238, 250, 255) !important;  /* Wednesday */

}
.conduct-list-item-date4 {
    background-color: rgb(238, 255, 249) !important; /* Thursday */

}
.conduct-list-item-date5 {
    background-color: rgb(255, 241, 227) !important;  /* Friday */

}
.conduct-list-item-date6 {
    background-color: rgb(227, 227, 227) !important; /* Saturday */
}

.conduct-list-item-data {
    overflow-wrap: break-word;
    width: 80%;
    padding: 1em;
}

.conduct-list-item-data a {
    text-decoration: none;
}

.attendance-champion {
    background-color: #ad829d;
}

.attendance-excellent {
    background-color: #a4c085;
}

.attendance-impressive {
    background-color: #c2d5ad;
}

.attendance-nearlythere {
    background-color: #e8cea3;
}

.attendance-improve {
    background-color: #deb777;
}

.attendance-danger {
    background-color: #c08870;
}

.attendance-impact {
    background-color: #a95431;
}

.conduct-list-item-info {
    
}