@import "./../../Variables.scss";

.dash-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-top: 1px solid #ccc;
    cursor: pointer;
    &:hover {
        background-color: $primary;

        color: $light;
    }
    .dash-list-item__title {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .dash-list-item__icon {
        font-size: 1.2rem;
        color: #999;
    }
}
.dash-list {
    border-bottom: 1px solid #ccc;
}
.dash-list-item-link {
    color: #999;
    text-decoration: none;

}
.dash-list-item-alt {
    background-color: $light;
}